import { useEffect } from "react";
import { items_state, products_state } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import { useSpring, animated } from "react-spring";
import { Line } from "../Line/Line";
import getSymbolFromCurrency from "currency-symbol-map";

export const ItemsHeader = ({ component, componentItems, displayActiveItem, displaySectionTitle }) => {
  const [itemsState] = useAtom(items_state);
  const [productsState] = useAtom(products_state);

  const [animationProps, animate] = useSpring(() => ({
    from: { opacity: 0, scale: 0 },
  }));

  const activeItemName = displayActiveItem && itemsState.activeObjs[component._id].displayName;

  //TODO: improve this
  const price =
    itemsState.activeObjs[component._id]?.price > 0 &&
    itemsState.activeObjs[component._id]?.reactComponent === "textItem" &&
    itemsState.activeObjs[component._id]?.price;

  useEffect(() => {
    animate.start({
      from: { opacity: 0, scale: 0 },
      to: { opacity: 1, scale: 1 },
    });
  }, []);

  return (
    <animated.div style={animationProps} className="flex-column mb-2">
      <div className="d-flex row position-relative">
        {displaySectionTitle && (
          <div className="text-center">
            <h6 className="sectionHeader mb-1">{component.displayName.toUpperCase()}</h6>
            <Line color="black" height="1px" width="30px" boxShadow="none" />
          </div>
        )}
        {price && (
          <div className="priceInHeader ms-auto">
            <h6>{`${getSymbolFromCurrency(productsState.activeObj?.shopify?.currency?.active ?? "USD")}${price}`}</h6>
          </div>
        )}
      </div>

      {component?.description && (
        <div className="description text-center mx-auto w-75">
          <p className="mb-1">{component.description}</p>
        </div>
      )}
      {activeItemName && (
        <div className="text-center">
          <h5 className="sectionHeader">{activeItemName}</h5>
        </div>
      )}
    </animated.div>
  );
};
