import React, { useEffect, useMemo, useRef } from "react";
import { useAtom } from "jotai";
import "./styles.scss";
import {
  items_state,
  // loading_state
} from "../../dataManagers/GlobalDataManagers";

// composites text onto a canvas to be used as a texture in the scene
export function CanvasCompositor({ applicableComponentId, componentColorController, textureAtom }) {
  const [itemsState] = useAtom(items_state);
  const [, setCustomPatchObj] = useAtom(textureAtom);
  // const [, setLoadingState] = useAtom(loading_state);

  const textItem = useMemo(() => {
    let textItem;
    Object.keys(itemsState.activeObjs).forEach((componentId) => {
      if (componentId?.includes(applicableComponentId)) {
        textItem = itemsState.activeObjs[componentId];
      }
    });
    return textItem;
  }, [applicableComponentId, itemsState]);

  const uploadedImageItem = useMemo(() => {
    return itemsState.array.find((item) => item?._id === "customImage-front-patch");
  }, [itemsState]);

  const activeItemType = useMemo(() => {
    return itemsState.activeObjs[applicableComponentId]._id.includes("customImage") ? "IMAGE" : "TEXT";
  }, [applicableComponentId, itemsState.activeObjs[applicableComponentId]._id]);

  const canvasRef = React.useRef();
  const ctxRef = React.useRef();
  const canvasSize = 256;
  const canvasPadding = 21;

  // custom text
  const text = textItem?.text_input;
  const fontIndex = textItem?.active_font_index;
  const font = textItem?.font_array?.[fontIndex];
  const fontColor = itemsState.activeObjs[componentColorController]?._id;
  // uploaded custom logo
  const uploadedImgSrc = uploadedImageItem.uploaded_logo_src;
  const uploadedImgData = uploadedImageItem?.uploaded_logo_base64;
  // anytime the src changes...
  useEffect(() => {
    if (text && font && activeItemType === "TEXT") {
      compositeTextOntoCanvas();
    } else if (uploadedImgSrc && activeItemType === "IMAGE") {
      handleNewUploadedImageData();
    }
  }, [text, font, uploadedImgSrc, uploadedImgData, fontColor]);

  /**
   *
   *
   * prepare and composite the data onto a canvas
   *
   *
   */

  function handleNewUploadedImageData() {
    // check uploadedImgSrc to see if we should use uploadedImgData (base64) or uploadedImgSrc (url)
    let validImgSrc;
    if (uploadedImgSrc.slice(0, 4) === "temp") validImgSrc = uploadedImgData;
    else validImgSrc = uploadedImgSrc;

    // happens when experience is loaded with uploaded images that weren't saved
    if (!validImgSrc) {
      setCustomPatchObj(null); // reseting cause there's no image
      return;
    }

    loadUploadedImage(validImgSrc);
  }

  async function loadUploadedImage(imgSrc) {
    let { isImgAlreadyLoaded, imgEl } = checkImgCache(false, imgSrc);

    if (isImgAlreadyLoaded) {
      compositeImgOnCanvas({ target: imgEl });
    } else {
      // show loading screen over scene (FYI doesn't have an effect when img is loaded as base64 aka user upload cause it's such a fast load)
      // setLoadingState(true);

      // load image
      imgEl = document.createElement("img");
      imgEl.src = imgSrc
      imgEl.crossOrigin = "anonymous";
      imgEl.onload = compositeImgOnCanvas;
      document.getElementById("imgInject").appendChild(imgEl);
    }
  }

  function compositeImgOnCanvas(loadEvt) {
    let image = loadEvt.target;
    ctxRef.current = canvasRef.current.getContext("2d");

    // draw image centered on canvas with correct aspect
    var hRatio = canvasRef.current.width / image.width;
    var vRatio = canvasRef.current.height / image.height;
    var ratio = Math.min(hRatio, vRatio);
    var centerShift_x = (canvasRef.current.width - image.width * ratio) / 2;
    var centerShift_y = (canvasRef.current.height - image.height * ratio) / 2;
    ctxRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctxRef.current.drawImage(image, 0, 0, image.width, image.height, centerShift_x, centerShift_y, image.width * ratio, image.height * ratio);

    let srcImgAspectRatio = image.width / image.height;

    // update engraving texture object for 3D scene
    let newObj = {
      canvas: canvasRef.current,
      normalMap: null,
      srcImgAspectRatio: srcImgAspectRatio,
      type: "IMAGE",
    };

    setCustomPatchObj(newObj);
  }

  function checkImgCache(isRequestingBool, imgSrc) {
    let isImgAlreadyLoaded = false,
      imgEl;
    let imgInject = document.getElementById("imgInject");

    // checking if we already have the img loaded
    if (imgInject.lastChild) {
      imgInject.childNodes.forEach((el) => {
        if (el.getAttribute("src") === imgSrc && el.complete) {
          isImgAlreadyLoaded = true;
          imgEl = el;
        }
      });
    }

    if (isRequestingBool) {
      if (isImgAlreadyLoaded) return true;
      else return false;
    } else {
      return { isImgAlreadyLoaded, imgEl };
    }
  }

  const colorPickerCtx = useMemo(() => {
    return document.getElementById("colorPicker_canvas").getContext("2d", { willReadFrequently: true });
  }, []);

  async function compositeTextOntoCanvas() {
    let ctx = ctxRef.current;
    let canvas = canvasRef.current;

    ctx = canvas.getContext("2d");

    let fontName = font.fontName;
    let fontSize = canvasSize + font.fontSizeBuffer;
    let fontString = `${fontSize}px ${fontName}`;

    await document.fonts.load(fontString);

    // get fill color from image data
    let img = await loadImageAsync(itemsState.activeObjs[componentColorController]?.imageSrc);
    colorPickerCtx.drawImage(img, 0, 0);
    const colorData = colorPickerCtx.getImageData(0, 0, 1, 1).data;
    ctx.fillStyle = `rgb(${colorData[0]}, ${colorData[1]}, ${colorData[2]})`;

    ctx.textAlign = "center";
    ctx.font = fontString;

    /**
     * calc the correct font size to fit on the canvas
     * if text protrudes outside canvas bounds, make it smaller
     */

    // let extraPadding = text.length == 1 ? 42 : 0; // prevents single-digit numbers from being super big compared to two-digit numbers
    let extraPadding = 0;
    extraPadding += textItem.extraData.extraPadding; // add some more padding for the circle cut texture so it doesn't get too close to edges
    const totalPadding = canvasPadding + extraPadding;

    let fontStats = ctx.measureText(text);
    if (fontStats.width > canvasSize - totalPadding) {
      // too wide
      for (fontSize; fontSize < fontSize + 1; fontSize--) {
        // update font with new size
        fontString = `${fontSize}px ${fontName}`;
        ctx.font = fontString;
        fontStats = ctx.measureText(text);
        // check if it fits in canvas bounds
        if (fontStats.width <= canvasSize - totalPadding) break;
      }
    }
    // adjust height if needed
    let heightObj = getTextHeight(text, fontString);
    if (heightObj.ascent > canvasSize - totalPadding) {
      // too tall
      let newFontSize = fontSize - (Math.abs(heightObj.ascent - fontSize) + totalPadding);
      fontString = `${newFontSize}px ${fontName}`;
      ctx.font = fontString;
    }

    // get font stats again
    fontStats = ctx.measureText(text);
    heightObj = getTextHeight(text, fontString);

    // composite the canvas
    let centeredHeight = canvas.height / 2 + (fontStats.actualBoundingBoxAscent - fontStats.actualBoundingBoxDescent) / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillText(text, canvas.width / 2, centeredHeight);

    let srcImgAspectRatio = fontStats.width / heightObj.height;

    // update engraving texture object for 3D scene
    let newObj = {
      canvas: canvas,
      normalMap: null,
      srcImgAspectRatio: srcImgAspectRatio,
      type: "TEXT",
    };

    setCustomPatchObj(newObj);
  }

  var getTextHeight = function (string, font) {
    var textEl = document.createElement("span");
    textEl.innerHTML = string;
    textEl.style.font = font;

    var blockEl = document.createElement("div");
    blockEl.style.display = "inline-block";
    blockEl.style.width = "1px";
    blockEl.style.height = "0px";

    var div = document.createElement("div");
    div.append(textEl, blockEl);

    var body = document.body;
    body.append(div);

    try {
      var result = {};
      blockEl.style.verticalAlign = "baseline";
      result.ascent = blockEl.offsetTop - textEl.offsetTop;
      blockEl.style.verticalAlign = "bottom";
      result.height = blockEl.offsetTop - textEl.offsetTop;
      result.descent = result.height - result.ascent;
    } finally {
      div.remove();
    }

    return result;
  };

  function loadImageAsync(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  return (
    <>
      {applicableComponentId === "custom-patch-front" && <span id="imgInject"></span>}

      <canvas id={`canvas_${applicableComponentId}`} className="customCanvas" ref={canvasRef} width={canvasSize} height={canvasSize}></canvas>
    </>
  );
}
