import React, { useEffect, useState } from "react";
import { Router } from "wouter";
import EmbedController from "../../../embed/EmbedController";
import { useHashLocation, useHashRoute } from "./useHash";

import AlertSlackOfError from "../../../monitoring/AlertSlackOfError";
import { GlobalDataManagers, defaultComponentId, defaultProductId } from "./GlobalDataManagers";

/**
 *
 * This component manages reading and writing hash data in the URL, which defines the global state data
 * Reads active id's from URL hash and passes them down to the global data managers
 * Writes URL whenever global data managers call function to update an activeId, which was passed down to them
 *
 */

// URL params
// Ex. /#/:productActiveId/:componentActiveId/:itemsActiveIds
const productParam = "productActiveId";
const componentParam = "componentActiveId";
const itemsParam = "itemsActiveIds";

// consumes the URL hash and creates data we need
const usePathParams = (hashLocation) => {
  const [productMatch, productParams] = useHashRoute(`#/:${productParam}`, hashLocation);
  const [componentMatch, componentParams] = useHashRoute(`#/:${productParam}/:${componentParam}`, hashLocation);
  const [itemsMatch, itemsParams] = useHashRoute(`#/:${productParam}/:${componentParam}/:${itemsParam}`, hashLocation);

  // FYI default data is in GlobalDataManagers.js
  let paramsObj = {};
  paramsObj[productParam] = defaultProductId();
  paramsObj[componentParam] = defaultComponentId();
  paramsObj[itemsParam] = null;

  // edit params template according to current path
  if (!productMatch && !componentMatch && !itemsMatch) {
    return paramsObj;
  } else if (productMatch) {
    paramsObj[productParam] = productParams[productParam] || defaultProductId();
  } else if (componentMatch) {
    paramsObj[productParam] = componentParams[productParam] || defaultProductId();

    if (!componentParams[componentParam]?.includes("%") && !componentParams[componentParam]?.includes("{") && componentParams[componentParam] != "undefined")
      // make sure component param isn't accidentally the item obj
      paramsObj[componentParam] = componentParams[componentParam] || defaultComponentId();
  } else if (itemsMatch) {
    paramsObj[productParam] = itemsParams[productParam] || defaultProductId();

    if (!itemsParams[componentParam]?.includes("%") && !itemsParams[componentParam]?.includes("{") && itemsParams[componentParam] != "undefined") {
      // make sure component param isn't accidentally the item obj
      paramsObj[componentParam] = itemsParams[componentParam] || defaultComponentId();
      paramsObj[itemsParam] = uriToJSONString(itemsParams[itemsParam]);
    }
  }

  return paramsObj;
};

let hasSlackBeenAlerted = false;
// converts a URI to a json string or null if it's invalid json
function uriToJSONString(uri) {
  try {
    var stringUri = decodeURIComponent(uri);
    var objectUri = JSON.parse(stringUri);
    if (objectUri && typeof objectUri === "object") return stringUri;
  } catch (e) {
    console.log("hasSlackBeenAlerted", hasSlackBeenAlerted);
    if (!hasSlackBeenAlerted) {
      AlertSlackOfError("uriToJSONString() in UrlDataController.js", `Resetting invalid URI to null: ${uri}`);
      hasSlackBeenAlerted = true;
      alert("The URL you input was incorrectly formatted. To prevent an error, we've reset the configuration (URL)");
    }
  }
  return null;
}

// Custom code to decode part of items
function itemsDecode(items) {
  try {
    if (items && items["custom-patch-front"]?.inputs) {
      items["custom-patch-front"].inputs.uploaded_logo_src = atob(items["custom-patch-front"].inputs.uploaded_logo_src);
    }
  } catch (error) {
    console.error(error);
  }
  return items;
}

// Custom code to encode part of items
function itemsEncode(items) {
  if (items && items["custom-patch-front"]?.inputs?.uploaded_logo_src) {
    items["custom-patch-front"].inputs.uploaded_logo_src = btoa(items["custom-patch-front"].inputs.uploaded_logo_src);
  }
  return items;
}

export function UrlDataController({ children }) {
  const [hashLocation, setHashLocation] = useHashLocation();

  // consume URL hash and get data we need from it
  const paramsObj = usePathParams(hashLocation);

  /**
   *
   * products
   *
   */

  // when some child wants to update product active id,
  // update the URL hash with newId
  function update_products_activeId_inURL(newId) {
    updateURL(productParam, newId);
  }

  /**
   *
   * components
   *
   */

  // when some child wants to update components active id,
  // update the URL hash with newId
  function update_components_activeId_inURL(newId) {
    updateURL(componentParam, newId);
  }

  /**
   *
   * items
   *
   */

  // when some child wants to update items active ids,
  // update the URL hash with newIdObj
  function update_items_activeIds_inURL(newIdObj) {
    updateURL(itemsParam, newIdObj);
  }

  /**
   *
   * CUSTOM CODE:
   * components & items at same time
   *
   */

  function update_component_and_items_inURL(newComponentId, newItemIds) {
    updateURL("componentAndItems", { componentId: newComponentId, itemIds: newItemIds });
  }

  /**
   *
   *
   * Helper to update URL hash with new active id
   *
   *
   */

  // helper to endcode and decode activeIds for URL
  function jsonToURI(json) {
    return encodeURIComponent(JSON.stringify(json));
  }

  function updateURL(paramToUpdate, newValue) {
    let hashPath = "#/";

    // products
    if (paramsObj[productParam] || paramToUpdate === productParam) {
      hashPath += `${paramToUpdate === productParam ? newValue : paramsObj[productParam]}/`;
    }

    // handle case where both component and items need to be updated at same time
    if (paramToUpdate === "componentAndItems") {
      const encodedItems = itemsEncode(newValue.itemIds);
      hashPath += `${newValue.componentId}/${jsonToURI(encodedItems)}`;
    } else {
      // components
      if (paramsObj[componentParam] || paramToUpdate === componentParam) {
        hashPath += `${paramToUpdate === componentParam ? newValue : paramsObj[componentParam]}/`;
      }
      // handle bug when there's no components_activeId
      else if (paramsObj[itemsParam] || paramToUpdate === itemsParam) {
        hashPath += `${defaultComponentId()}/`;
        console.log(`_____blank component id. HashPath is manually set to ${hashPath}`);
        AlertSlackOfError("updateURL() in UrlDataController.js", `blank component id. HashPath is manually set to ${hashPath}`);
      }

      // items
      if (paramsObj[itemsParam] || paramToUpdate === itemsParam) {
        // encoding obj to string
        hashPath += `${paramToUpdate === itemsParam ? jsonToURI(newValue) : paramsObj[itemsParam]}`;
      }
    }

    /**
     * update the URL
     */
    if (hashLocation != hashPath) {
      // if embedded, update parent URL hash, which will reactivly update this embed URL hash (see code below)
      if (EmbedController.isEmbedded) EmbedController.sendHashChange(hashPath);
      else setHashLocation(hashPath);
    }
  }

  /**
   *
   *
   * If experience is embedded in parent site,
   * we send hash update requests to the parent
   * then react to hash updates from the parent by copying its hash here in the embed
   *
   */
  const [parentHashLocation, setParentHashLocation] = useState(window._tt?.initialParentHash || "#/");

  // setup listeners to react to parent site if we're embedded
  useEffect(() => {
    if (EmbedController.isEmbedded) {
      // setup callback for when parent hash is updated
      EmbedController.setHashChangeCallback((hash) => {
        setParentHashLocation(hash);
      });
    }
  }, []);

  // when parentHashLocation is updated, we update the embed's hash location, which controls the experience
  useEffect(() => {
    if (EmbedController.isEmbedded && parentHashLocation && parentHashLocation != hashLocation) {
      setHashLocation(parentHashLocation);
    }
  }, [parentHashLocation]);

  // when the browser back or forward btn's are used the hashLocation changes but parentHashLocation doesn't so we need to send the update to the parent
  // NOTE: I'm aware this is backwards compared to the rest of our URL logic (updates should be passed from parent to embed) but that would require us to handle the embedded experience and standalone's URL logic differently, which is less ideal
  useEffect(() => {
    if (EmbedController.isEmbedded) {
      // don't send duplicate updates (when embed hash and parent hash are equal) and don't update if experience is just starting (hashLocation is #/)
      if (hashLocation != parentHashLocation && hashLocation != "#/") {
        EmbedController.sendHashChange(hashLocation);
      }
    }
  }, [hashLocation]);

  // memoize so the GDM only re-renders when these values have actually changed
  const products_activeId = React.useMemo(() => paramsObj[productParam], [paramsObj[productParam]]);
  const components_activeId = React.useMemo(() => paramsObj[componentParam], [paramsObj[componentParam]]);
  const items_activeIds = React.useMemo(() => {
    // handle base64 to string conversion for potential image data coming from URL
    const parsedObject = itemsDecode(JSON.parse(paramsObj[itemsParam]));
    return parsedObject;
  }, [paramsObj[itemsParam]]);

  return (
    <Router basePath={"/"} hook={useHashLocation}>
      <GlobalDataManagers
        // products
        products_activeId_fromURL={products_activeId}
        update_products_activeId_inURL={update_products_activeId_inURL}
        // components
        components_activeId_fromURL={components_activeId}
        update_components_activeId_inURL={update_components_activeId_inURL}
        // items
        items_activeIds_fromURL={items_activeIds}
        update_items_activeIds_inURL={update_items_activeIds_inURL}
        // components and items
        update_component_and_items_inURL={update_component_and_items_inURL}
      />

      {/* children is the ProductBuilder */}
      {children}
    </Router>
  );
}
