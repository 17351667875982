import { useCallback, useEffect, useState } from "react";
import { getStepActiveIndex } from "../../modules/getStepperActiveIndex";
import { Boop } from "../UI/Animations/Boop";
import { ImgWithFallback } from "../UI/ImgWithFallback/ImgWithFallback";
import { pushGTMAnalyticsEvent } from "../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

export const Navigation = ({ steps, componentsStateObj, setShowCartTerms }) => {
  const activeComponentIndex = getStepActiveIndex(steps, componentsStateObj.state);

  const handlePrevious = useCallback(() => {
    if (activeComponentIndex != 0) {
      componentsStateObj.setActiveId(steps[activeComponentIndex - 1].components[0]);

      pushGTMAnalyticsEvent({
        event: "Navigation Flag Button Click - Thrill",
        flagLabel: "Prev",
        page: steps[activeComponentIndex].displayName.toLowerCase(),
      });
    }
  }, [componentsStateObj.state.activeId]);

  const handleNext = useCallback(() => {
    const nextStep = steps[activeComponentIndex + 1];
    if (nextStep._id === "shopping_cart") {
      setShowCartTerms(true);
      return;
    }
    componentsStateObj.setActiveId(nextStep.components[0]);

    pushGTMAnalyticsEvent({
      event: "Navigation Flag Button Click - Thrill",
      flagLabel: activeComponentIndex === 2 ? "Cart" : "Next",
      page: steps[activeComponentIndex].displayName.toLowerCase(),
    });
  }, [componentsStateObj.state.activeId]);

  return (
    <div className="navigation mt-1 d-flex align-items-center justify-content-center" style={{ bottom: `0px` }}>
      <Boop boopType="scale" scale="1.1" timing={200}>
        <ImgWithFallback
          src="/images/nav_btn_flags/prev-btn-flag.webp"
          fallback="/images/nav_btn_flags/prev-btn-flag.png"
          alt="previous button"
          className={`mx-3 ${activeComponentIndex === 0 && `disable`}`}
          onClick={handlePrevious}
        />
      </Boop>

      <h4 className="text-center pt-1 my-auto">{`Select ${steps[activeComponentIndex].displayName}`}</h4>

      <Boop boopType="scale" scale="1.1" timing={200}>
        <ImgWithFallback
          src={activeComponentIndex === 1 ? "/images/nav_btn_flags/cart-btn-flag.webp" : "/images/nav_btn_flags/next-btn-flag.webp"}
          fallback={activeComponentIndex === 1 ? "/images/nav_btn_flags/cart-btn-flag.png" : "/images/nav_btn_flags/next-btn-flag.png"}
          alt="next button"
          className="mx-3"
          onClick={handleNext}
        />
      </Boop>
    </div>
  );
};
