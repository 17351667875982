import React from "react";
import { BuilderScreen } from "./UI/BuilderScreen/BuilderScreen";
import { BuilderUI } from "./UI/BuilderUI/BuilderUI";
import Scene from "./r3f/Scene.js";
import NetworkError from "./UI/NetworkError";

export default function ProductBuilder() {
  return (
    <>
      <BuilderScreen>
        <NetworkError />
        <BuilderUI />
        <Scene />
      </BuilderScreen>
    </>
  );
}
