import React from "react";
import MaterialsScreen from "./MaterialsScreen";
import PatchesScreen from "./PatchesScreen";
import BaseScreen from "./BaseScreen";
import { getStepActiveIndex } from "../modules/getStepperActiveIndex";

// maps products.components _id to screens
const Components = {
  materials: MaterialsScreen,
  patches: PatchesScreen,
  base: BaseScreen,
};

export const CreateScreens = ({ componentsState, productsState, ...props }) => {
  const activeComponentIndex = getStepActiveIndex(productsState.array[0].category, componentsState);
  // make sure we have a valid component coming from products.json
  if (typeof Components[productsState.activeObj.category[activeComponentIndex]._id] !== "undefined") {
    // create and render the component depending on activeObj.react.component
    return React.createElement(Components[productsState.activeObj.category[activeComponentIndex]._id], {
      key: productsState.activeObj.category[activeComponentIndex]._id,
      componentsState,
      productsState,
      ...props,
    });
  }
  // component wasn't found
  return React.createElement(() => <div>The component {productsState.activeObj.category[activeComponentIndex]._id} has not been created yet.</div>, {
    key: Math.floor(Math.random() * 100),
  });
};
