import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { getStepActiveIndex } from "../../../modules/getStepperActiveIndex";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import "./styles.scss";

// stepper styles
const ipad = 767;
const md = 769;

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#000000", // black
    zIndex: 1,
    borderRadius: "50%",
    cursor: "pointer",
    width: 20,
    height: 20,
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)", // black

    [theme.breakpoints.up(ipad)]: {
      width: 20,
      height: 20,
    },
    [theme.breakpoints.up(md)]: {
      width: 20,
      height: 20,
    },
  },
  active: {
    backgroundColor: "#c71608", // red
    boxShadow: "0 4px 10px 0 rgb(230 36 41 / 50%)",
  },

  stepCountActive: {
    color: "#F9F6EE", // red
    fontSize: "12px",
    marginTop: "1px",
  },
  stepCount: {
    color: "#999", // charcoal
    fontSize: "12px",
    marginTop: "1px",
    "&:hover": {
      color: "#F9F6EE", // red
    },
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed, icon } = props;

  return (
    <div
      className={`${clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })} text-center`}
    >
      <p className={`no-user-select ${active ? classes.stepCountActive : classes.stepCount}`}>{icon}</p>
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

export default function CustomStepper({ steps, componentsStateObj }) {
  const activeComponentIndex = getStepActiveIndex(steps, componentsStateObj.state);
  const handleClick = (section) => {
    componentsStateObj.setActiveId(section.components[0]);

    pushGTMAnalyticsEvent({
      event: "Navigation Stepper Click - Thrill",
      stepperLabel: section.displayName,
      page: steps[activeComponentIndex].displayName.toLowerCase(),
    });
  };

  return (
    <Stepper className="Stepper p-0 pt-3 mb-2" alternativeLabel activeStep={activeComponentIndex}>
      {steps.map((step, index) => {
        if (step._id.includes("cart")) return null;
        return (
          <Step key={step.displayName} onClick={() => handleClick(step)}>
            <StepLabel icon={index + 1} StepIconComponent={ColorlibStepIcon}>
              {step.displayName}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
}

CustomStepper.propTypes = {
  /**
   * Contains labels and activeIndex
   */
  stepperData: PropTypes.object,
  /**
   * Contains an array of colors for each step
   */
  stepColors: PropTypes.array,

  /**
   * Updates stepper activeIndex when clicked
   */
  setStepper: PropTypes.func,
};
