import { animated } from "react-spring";
import { Boop } from "../Animations/Boop";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";

export const TextItem = ({ item, component, animation }) => {
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);
  const isActive = itemsState.activeIds[component._id]._id === item._id ? "activeText" : null;

  const handleClick = () => {
    // abort if active item was reclicked
    if (itemsState.activeIds[component._id]._id === item._id) return;

    let copy = { ...itemsState.activeIds };
    copy[component._id] = { _id: item._id };

    setItemsActiveIds(copy);

    pushGTMAnalyticsEvent({
      event: "Item Click - Thrill",
      page: component.category,
      itemSection: component._id,
      item: item._id,
    });
  };

  return (
    <animated.div style={animation}>
      <Boop boopType="scale" scale={1.02} timing={200}>
        <button onClick={handleClick} className={`rounded-button ${isActive}`}>
          {item.displayName}
        </button>
      </Boop>
    </animated.div>
  );
};
