import React from "react";
import { useAtom } from "jotai";
import { ItemsSection } from "../UI/ItemsSection/ItemsSection";
import { Accordion } from "../UI/Accordion/Accordion";
import { NestedAccordion } from "../UI/Accordion/NestedAccordion";
import { InputBox } from "../UI/InputBox/InputBox";
import { FontSection } from "../UI/FontSection/FontSection";
import { items_state, accordion_state } from "../dataManagers/GlobalDataManagers";
import { FileDragDrop } from "../UI/FileDragDrop/FileDragDrop";
import { ImageGallery } from "../UI/ImageGallery";

const Components = {
  ItemsSection,
  Accordion,
  NestedAccordion,
  InputBox,
  FontSection,
  FileDragDrop,
  ImageGallery
};

export const CreateReactComponent = ({ component, reactSchema, componentItems, ...props }) => {
  const [itemsState] = useAtom(items_state);

  /* 
  🛑This is custom code for Thrill Seekers. 
  Since customText-material-front && custom-patches-side is a product component, 
  as opposed to a react component,
  but needs to be conditionally hidden in the accordion we had to add that here
  */
  const [accordionState] = useAtom(accordion_state);
  if ((component._id === "customText-material-front" || component._id === "patch-material-front") && accordionState !== "custom-patch-front") {
    return null;
  }
  if ((component._id === "customText-material-sides" || component._id === "patch-material-sides") && accordionState !== "custom-patches-side") {
    return null;
  }
  const ribsMaterials = ["ribs-material-all", "ribs-material-1", "ribs-material-2", "ribs-material-3", "ribs-material-4", "ribs-material-5", "ribs-material-6"];
  if (ribsMaterials.includes(component._id) && accordionState !== "rib-type") {
    return null;
  }
  /* End of thrill seekers custom code*/

  if (typeof Components[reactSchema.component] !== "undefined") {
    return React.createElement(
      Components[reactSchema.component],
      {
        component,
        componentItems,
        ...reactSchema.props,
        ...props,
      },
      reactSchema?.children &&
      (typeof reactSchema.children === "string"
        ? reactSchema.children
        : reactSchema?.children?.map(
          (c, index) =>
            // check if child has dependency on items
            (itemsState.activeIds[component._id]._id === c.dependency || !c.dependency) && (
              <CreateReactComponent key={index} component={component} reactSchema={c} componentItems={componentItems} {...c.props} />
            )
        ))
    );
  }

  return React.createElement(() => <div>{reactSchema.component} hasn't been made</div>, { key: Math.floor(Math.random() * 100) });
};
