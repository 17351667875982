import Card from "react-bootstrap/Card";
import { animated } from "react-spring";
import { Boop } from "../Animations/Boop";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";

export const CardItem = ({ component, item, animation }) => {
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);
  const isActive = itemsState.activeIds[component._id]._id === item._id;

  const handleClick = () => {
    // abort if active item was reclicked
    if (itemsState.activeIds[component._id]._id === item._id) return;

    let copy = { ...itemsState.activeIds };
    copy[component._id] = { _id: item._id };

    setItemsActiveIds(copy);

    pushGTMAnalyticsEvent({
      event: "Item Click - Thrill",
      category: component.category,
      itemSection: component.displayName,
      item: item.displayName,
    });
  };

  return (
    <animated.div style={animation}>
      <Boop boopType="scale" scale={1.02} timing={200}>
        {isActive && <img className="checkMark" alt="check mark" src="/images/checkmark.svg" />}
        <Card onClick={() => handleClick(item._id)} className={`cardItem mx-auto mb-3 ${!isActive && "disabled"}`}>
          <div className={`d-flex mx-auto mb-2 imgFrame zoomed`}>
            {item?.thumbnail ? (
              <ImgWithFallback
                className={`card-img-top mx-auto my-auto imageCard ${component.category}`}
                src={item.thumbnail[0]}
                fallback={item.thumbnail[1] || item.thumbnail[0]}
                onClick={handleClick}
              />
            ) : (
              <h4 className="my-auto">{item?.cardText}</h4>
            )}
          </div>
          <Card.Body className="p-0">
            <Card.Title className="text-center">{item.displayName}</Card.Title>
          </Card.Body>
        </Card>
      </Boop>
    </animated.div>
  );
};
