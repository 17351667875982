// translates the activeComponentId into an int

// used when project has steps in products.json (i.e. Thrill)
export const getStepActiveIndex = (steps, componentsState) => {
  return steps.findIndex((step) => step.components.includes(componentsState.activeObj._id));
};

// used when project relies on component data for stepper
export const getComponentStepperActiveIndex = (componentsState) => {
  return componentsState.array.findIndex((component) => component._id === componentsState.activeId);
};
