import { memo } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useMemo } from "react";
import { useTransition } from "react-spring";
import { ImageItem } from "./ImageItem";
import { TextItem } from "./TextItem";
import { CardItem } from "./CardItem";
import { ItemsHeader } from "./ItemsHeader";
import useFilterColor from "./useFilterColor";
import "./styles.scss";

export const ItemsSection = memo(({ component, componentItems, children, ...props }) => {
  const filteredColors = useFilterColor({ componentItems, component })
  const animationOnItems = useTransition(filteredColors, {
    trail: 40,
    initial: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    update: { enter: { opacity: 1, scale: 1 } },
    reset: true,
  });

  // the reactComponent is set on each item in items.json
  const getItemComponent = (item, style) => {
    const itemToComponentMap = {
      textItem: <TextItem component={component} item={item} animation={style} />,
      imageItem: <ImageItem component={component} item={item} animation={style} />,
      cardItem: <CardItem component={component} item={item} animation={style} />,
    };
    return itemToComponentMap[item.reactComponent];
  };

  const dataToDisplayTransitionArray = useMemo(() => animationOnItems, [component]);

  const colNumber = useMemo(() => {
    if (component.items.length < 2) return 6
    else if (component.items.length === 3)
      return 4
    else return 3
  }, [component.items.length])
  // button items shown in UI
  const items = dataToDisplayTransitionArray((style, item, key, index) => {
    return (
      <Col key={item._id} xs={colNumber} className="my-1">
        {getItemComponent(item, style)}
      </Col>
    );
  });

  return (
    <div className="ItemsSection my-3">
      <ItemsHeader component={component} componentItems={componentItems} {...props} />

      <Row className="items text-center justify-content-around">
        {items}
        {children}
      </Row>
    </div>
  );
});
