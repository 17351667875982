import React, { useState, Children } from "react";
import sift from "sift";
import { CreateReactComponent } from "../components/utils/CreateReactComponent";
import { useComponentsIndex } from "../hooks/useComponentsIndex";

const itemsCache = {};
const Patches = ({ productsState, componentsState, itemsStateArray }) => {
  const { patchesIndex } = useComponentsIndex(productsState);
  const patchesComponents = componentsState.array
    .filter(
      sift({
        _id: { $in: productsState.activeObj.category[patchesIndex].components },
        $or: [{ excluded: { $exists: false } }, { excluded: false }],
      })
    )
    .sort((a, b) => {
      return (
        productsState.activeObj.category[patchesIndex].components.indexOf(a._id) - productsState.activeObj.category[patchesIndex].components.indexOf(b._id)
      );
    });
  const [openNestedAccordion, setOpen] = useState(null);

  const getItems = (component) => {
    // check if query is already cached
    if (itemsCache[component._id]) {
      return itemsCache[component._id];
    }

    // query is not cached, so fetch it, sort it, and add it to the cache
    itemsCache[component._id] = itemsStateArray
      .filter(
        sift({
          _id: { $in: component.items },
          $or: [{ outOfStock: { $exists: false } }, { outOfStock: false }],
        })
      )
      .sort((a, b) => {
        return component.items.indexOf(a._id) - component.items.indexOf(b._id);
      });
    return itemsCache[component._id];
  };

  return Children.toArray(
    patchesComponents.map((component, index) => {
      return (
        <>
          <CreateReactComponent
            component={component}
            reactSchema={component.reactSchema}
            componentItems={getItems(component)}
            openObjState={{ state: openNestedAccordion, setState: setOpen }}
          />
        </>
      );
    })
  );
};

export default Patches;
