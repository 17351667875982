/**
 * Calculates the final price of the configuration
 */
export const getFinalPrice = (productsState, componentsState, itemsState, shouldDiscountBeRemoved = false) => {
  // CUSTOM CODE
  let msrp = productsState.activeObj?.shopify?.msrp;
  const price = productsState.activeObj?.shopify?.price;
  let itemsPrice = 0;

  for (let key in itemsState.activeObjs) {
    if (itemsState.activeObjs[key]?.price && !componentsState.array.find((component) => component._id === key)?.excluded) {
      itemsPrice += itemsState.activeObjs[key].price;
    }
  }
  if (shouldDiscountBeRemoved && msrp) {
    msrp = convertMsrpToNativeCurrency(msrp);
    if (price !== msrp) {
      const discountPercentage = ((msrp - price) / msrp) * 100;
      const discountedItemsPrice = itemsPrice / (1 - discountPercentage / 100);
      return (msrp + discountedItemsPrice).toFixed(2);
    }
  }
  return (price + itemsPrice).toFixed(2);
};

export const convertToUSD = (price) => {
  const conversionRate = window._tt?.shopifyData?.currency?.rate;
  if (!conversionRate) return price;
  return (parseFloat(price) / parseFloat(conversionRate)).toFixed(2);
};

export const convertMsrpToNativeCurrency = (msrp) => {
  const conversionRate = window._tt?.shopifyData?.currency?.rate;
  if (!conversionRate) return msrp;
  return msrp * parseFloat(conversionRate);
};
