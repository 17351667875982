import React from "react";

// Function to check if it's an image key
const isImageKey = (propKey) => propKey.includes("Image");

// Function to check if it's a dash key
const isDashKey = (propKey) => propKey.includes("--");

// Component for Image
const ImageComponent = ({ src }) => <img className="d-flex mx-auto w-50" alt="customImage" src={src} />;

// Component for Key-Value pair
const KeyValuePair = ({ propKey, value }) => (
  <p className="final-items-review mb-2">
    <span className="fw-bold">{propKey}</span>: <span className="value">{value}</span>
  </p>
);

// Component for Key only
const KeyComponent = ({ propKey }) => (
  <p className="final-items-review mb-2">
    <span className="fw-bold">{propKey}</span>
  </p>
);

const FormatModalItems = ({ cartSubmissionObj }) => {
  let itemProperties = cartSubmissionObj.items?.[0]?.properties;

  // Return null if itemProperties is not defined
  if (!itemProperties) return null;

  // Map over the properties and return formatted elements
  return Object.entries(itemProperties).map(([key, value]) => {
    if (isImageKey(key)) {
      return <ImageComponent key={key} src={value} />;
    }
    if (isDashKey(key)) {
      return <KeyComponent key={key} propKey={key} />;
    }
    return <KeyValuePair key={key} propKey={key} value={value} />;
  });
};

export default FormatModalItems;
