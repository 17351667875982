import getSymbolFromCurrency from "currency-symbol-map";
import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { animated, useSpring } from "react-spring";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";
import { accordion_state, items_state, products_state } from "../../dataManagers/GlobalDataManagers";
import "./styles.scss";

export const Accordion = ({ component, children }) => {
  const [itemsState] = useAtom(items_state);
  const [productsState] = useAtom(products_state);
  const [accordionState, setAccordionState] = useAtom(accordion_state);
  const open = component._id === accordionState;
  const ref = useRef();

  // custom code to display rib thumbnail
  // TODO: this will need to be refactored once mutli-material is implemented
  const componentId = itemsState.activeObjs[component._id]._id === "single-material-ribs" ? "ribs-material-all" : component._id;
  // end of custom ribs code

  const displayThumbnail = itemsState.activeObjs[componentId]?.imageSrc;
  const accordionThumbnail = displayThumbnail ? itemsState.activeObjs[componentId]?.imageSrc : null;

  // if price should be shown in accordion, find the highest priced item and set that as the price shown
  let [highestPrice, setHighestPrice] = useState(0);
  useEffect(() => {

    if (component.characteristics && component.characteristics.includes("show-price-in-accordion")) {
      let highestPriceTemp = 0;
      const itemIds = component.items.map((item) => item);
      const items = itemsState.array.filter((item) => itemIds.includes(item._id));
      items?.forEach((item) => {
        if (item.price > highestPrice) {
          highestPriceTemp = item.price;
        }
      });
      if (highestPrice != highestPriceTemp) setHighestPrice(highestPriceTemp);
    }
  }, []);

  //toggle accordion function
  let toggleHandler = (e) => {
    setAccordionState(open ? null : component._id);

    // scroll to top
    scrollIntoView();

    !open &&
      pushGTMAnalyticsEvent({
        event: "Accordion Opened - Thrill",
        id: component._id,
        displayName: component.displayName,
        page: component.category,
      });
  };

  function scrollIntoView() {
    // abort if there are less than 5 items in the accordion on desktop
    if (component.items.length < 5 && !isMobile) return;
    setTimeout(() => {
      let buildSection = document.getElementById("BuildSection");
      if (buildSection && ref.current.offsetTop) {
        const scrollDestination = ref.current.offsetTop - 50;
        buildSection.scrollTop = scrollDestination;
      }
    }, 50);
  }

  const openAnimation = useSpring({
    from: { opacity: 1 },
    to: { opacity: 1 },
    config: { duration: 300 },
  });

  return (
    <>
      <animated.div ref={ref} className={`${open && "active"} accordion_item  mx-auto hover_effect my-2`} style={openAnimation} onClick={toggleHandler}>
        <div className="accordion_header d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-between" style={{ flex: 1 }}>
            <div className="d-flex align-items-center justify-content-center">
              <div>
                {itemsState.activeObjs[componentId]?._id?.includes("gripper") && (
                  <img className="previewThumbnail overlayTexture" src="/thrill-seekers-assets/textures/gripper-transparent.png" alt="Gripper" />
                )}
                {/* CUSTOM CODE: remove thumbnail for matching color item */}
                {accordionThumbnail && itemsState.activeObjs[componentId]._id !== "matching-color-stitching" && (
                  <img
                    className="previewThumbnail me-2"
                    src={accordionThumbnail}
                    alt="Thumbnail"
                    style={{ borderRadius: itemsState.activeObjs[componentId]?._id?.includes("color-logo") ? 0 : undefined }}
                  />
                )}
              </div>
              {component.characteristics.includes("show-price-in-accordion") ? (
                <h4 className="mb-0">{`${component.displayName.toUpperCase()} +${getSymbolFromCurrency(
                  productsState.activeObj?.shopify?.currency?.active ?? "USD"
                )}${highestPrice}`}</h4>
              ) : (
                <h4 className="mb-0">{component.displayName.toUpperCase()}</h4>
              )}
            </div>
            {component.isNew &&
              <div className="d-flex new-label">
                <img className="new-label" src="/images/new-label.png" alt="new-label" />
              </div>
            }
          </div>
          {open ?
            <img className="expandIcon" src="/images/minus.svg" alt="expand icon" /> :
            <img className="expandIcon" src="/images/plus.svg" alt="expand icon" />
          }
        </div>
      </animated.div>
      {open && children}
    </>
  );
};
