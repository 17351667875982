import { getStepActiveIndex } from "../../../modules/getStepperActiveIndex";
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import "./styles.scss";

export const BuildSection = ({ setShowCartTerms, steps, componentsStateObj, children }) => {
  const activeComponentIndex = getStepActiveIndex(steps, componentsStateObj.state);
  const ref = useRef();

  const handleClick = () => {
    let windowReference = window.open();
    windowReference.location = "https://tastetest3d.com/";

    // if (EmbedController.isEmbedded) {
    //   EmbedController.sendGTMAnalyticsEvent({
    //     'event': 'Powered By TT Click - MGP'
    //   })
    // }
  };

  useEffect(() => {
    if (ref.current) ref.current.scrollTop = 0;
  }, [activeComponentIndex]);

  return (
    <Container fluid ref={ref} id="BuildSection" className="d-flex flex-column">
      {children}
      <div className="brandmark-padding"></div>
      <div role="button" onClick={handleClick} className="tastetest-brandmark d-flex mt-auto mb-1 align-items-center justify-content-center">
        <p className="m-0">powered by</p>
        <img src="/images/TasteTest_BrandMark_Gradient.png" alt="Taste Test" />
      </div>
    </Container>
  );
};

BuildSection.propTypes = {
  children: PropTypes.node.isRequired,
  setShowCartTerms: PropTypes.func.isRequired,
};
