import { useEffect, useMemo } from "react";
import { useAtomValue, useSetAtom } from "jotai";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { ReactComponent as CheckmarkIcon } from '../../../../assets/checkMark.svg';
import "./styles.scss";

export const ImageGallery = ({ component, itemId, componentItems, label }) => {
  const itemsState = useAtomValue(items_state);
  const setItemsActiveIds = useSetAtom(update_items_activeIds);
  const _uploadedSrc = itemsState?.activeObjs['custom-patch-front']?.inputs?.uploaded_logo_src;
  const activeSrc = _uploadedSrc;
  const customImageItem = useMemo(() => (itemsState.array.find((item) => item._id === itemId)), [itemId, itemsState.array]);

  useEffect(() => {
    const copy = { ...itemsState.activeIds };
    let newActiveInput = {};
    newActiveInput._id = customImageItem._id;
    newActiveInput.inputs = customImageItem.inputs;
    const decodedSrc = customImageItem.uploaded_logo_src;
    newActiveInput.inputs.uploaded_logo_src = decodedSrc;
    copy[component._id] = newActiveInput;
    setItemsActiveIds(copy);
  }, []);

  const images = useMemo(() => {
    const _component = componentItems.find((item) => item._id === itemId);
    return _component?.images_array || [];
  }, [componentItems, itemId]);

  const handleClick = (e) => {
    if (e.target && activeSrc === e.target.src) {
      return
    };
    const copy = { ...itemsState.activeIds };
    let newActiveInput = {};

    newActiveInput._id = customImageItem._id;
    newActiveInput.inputs = customImageItem.inputs;
    newActiveInput.inputs.uploaded_logo_src = e.target.src;
    customImageItem.uploaded_logo_base64 = '';

    copy[component._id] = newActiveInput;
    setItemsActiveIds(copy);
  }

  return (
    <div className="images-container d-flex row justify-content-center gap-2">
      <h5 className="sectionHeader mx-auto my-2 text-center">{label}</h5>
      <hr className="mx-auto m-0" />
      <div className="items text-center justify-content-start row">{
        images.map((src) => {
          return (
            <div key={src} className="gx-2 my-1 col-3 d-flex justify-content-center align-items-center">
              <div className={`${"image-container col-12 d-flex justify-content-center align-items-center"} ${activeSrc?.includes(src) ? "active-item" : ""}`}>
                {activeSrc?.includes(src) &&
                  <div className="checkmark">
                    <CheckmarkIcon />
                  </div>
                }
                <img onClick={handleClick} width="80px" height="90px" src={src} alt="" />
              </div>
            </div>
          )
        })
      }</div>
    </div>
  )
};
