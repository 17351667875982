import React, { useState, useEffect, useContext } from "react";
import { useThree } from "@react-three/fiber";
import * as THREE from "three";
import { RepeatWrapping, TextureLoader } from "three";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader.js";
import { BasisTextureLoader } from "three/examples/jsm/loaders/BasisTextureLoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
// import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';

const AssetSystemContext = React.createContext();
export function useAssetLoader() {
  return useContext(AssetSystemContext);
}

// for use in scene.js when useLoader is used, so that only 1 gltf and draco loader is created since multiple can cause bugs
const GLTFLoaderContext = React.createContext();
export function useGLTFLoader() {
  return useContext(GLTFLoaderContext);
}

const assetCache = {};

export default function AssetSystem3d({ children }) {
  const renderer = useThree((state) => state.gl);

  const [textureLoader, setTextureLoader] = useState(null);
  const [basisLoader, setBasisLoader] = useState(null);
  const [ktx2Loader, setKtx2Loader] = useState(null);
  const [gltfLoader, setGltfLoader] = useState(null);
  const [loadersReady, setLoadersReady] = useState(false);

  function updateAssetCache(assetURI, asset) {
    assetCache[assetURI] = asset;
  }
  function markAssetAsLoading(assetURI) {
    assetCache[assetURI] = "loading";
  }

  // _________________________________________________
  // start of loader creation code
  // _________________________________________________

  useEffect(() => {
    if (!loadersReady) {
      createLoaders();
      setLoadersReady(true);
    }
  }, []);

  function createLoaders() {
    const textureLoader = createTextureLoader();
    let basisLoader = createBasisTextureLoader();
    // const ktx2Loader = createKtx2Loader();
    // const gltfLoader = createModelLoader(ktx2Loader);
    const gltfLoader = createModelLoader();

    setTextureLoader(textureLoader);
    setBasisLoader(basisLoader);
    // setKtx2Loader(ktx2Loader)
    setGltfLoader(gltfLoader);
  }

  function createTextureLoader() {
    return new TextureLoader();
  }

  function createBasisTextureLoader() {
    var basisLoader = new BasisTextureLoader();
    basisLoader.setTranscoderPath("/basis/");
    basisLoader.detectSupport(renderer);
    return basisLoader;
  }

  function createKtx2Loader() {
    var ktx2Loader = new KTX2Loader();
    // ktx2Loader.setTranscoderPath( '/basis/' );
    ktx2Loader.setTranscoderPath("https://cdn.jsdelivr.net/gh/pmndrs/drei-assets@master/basis/");
    ktx2Loader.detectSupport(renderer);
    return ktx2Loader;
  }

  function createModelLoader(ktx2TextureLoader) {
    var gltfLoader = new GLTFLoader();
    var dracoLoader = new DRACOLoader();
    // dracoLoader.setDecoderPath( '/draco/' );
    dracoLoader.setDecoderPath("https://www.gstatic.com/draco/versioned/decoders/1.4.3/");
    gltfLoader.setDRACOLoader(dracoLoader);
    // gltfLoader.setKTX2Loader( ktx2TextureLoader );
    // gltfLoader.setMeshoptDecoder(MeshoptDecoder);
    return gltfLoader;
  }
  // _________________________________________________
  // end of loader creation code
  // _________________________________________________

  // _________________________________________________
  // start of asset loading code
  // _________________________________________________

  async function getAsset(assetURI, canvas) {
    if (!assetURI) return;

    // if asset is currently loading, just wait for it to load then return it
    if (assetCache[assetURI] === "loading") {
      await waitForAssetToLoad(assetURI);
      return assetCache[assetURI];
    }

    // if asset is present in cache, return it before loading
    if (assetCache[assetURI]) return assetCache[assetURI];

    // load new asset
    if (!canvas) markAssetAsLoading(assetURI);
    var asset;
    if (canvas) asset = await loadCanvasTexture(canvas);
    else if (assetURI?.includes("/models/")) asset = await loadModel(assetURI);
    else if (assetURI?.includes("/textures/") || assetURI?.includes(".jpg") || assetURI?.includes(".png")) asset = await loadTexture(assetURI);
    else {
      console.warn("AssetSystem3d: assetURI not recognized, loading as texture");
      asset = await loadTexture(assetURI);
    }

    return asset;
  }

  // checks for assetURI in the assetCache and resolves once present
  async function waitForAssetToLoad(assetURI) {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (assetCache[assetURI] && assetCache[assetURI] != "loading") {
          resolve();
          clearInterval(interval);
        }
      }, 50);
    });
  }

  async function loadModel(assetURI) {
    return new Promise((resolve) => {
      gltfLoader.load(assetURI, (gltf) => {
        updateAssetCache(assetURI, gltf);
        resolve(gltf);
      });
    });
  }

  async function loadTexture(assetURI) {
    return new Promise((resolve) => {
      getCorrectLoader(assetURI).load(assetURI, (texture) => {
        texture = prepareTexture(assetURI, texture);
        updateAssetCache(assetURI, texture);
        resolve(texture);
      });
    });
  }

  async function loadCanvasTexture(canvas) {
    return new Promise((resolve) => {
      let canvasTexture = new THREE.CanvasTexture(canvas);
      canvasTexture.wrapS = THREE.RepeatWrapping;
      canvasTexture.wrapT = THREE.RepeatWrapping;
      canvasTexture.encoding = THREE.sRGBEncoding;
      canvasTexture.flipY = false;
      resolve(canvasTexture);
    });
  }

  function getCorrectLoader(textureSrc) {
    var correctLoader;

    if (textureSrc?.includes("ktx2")) {
      correctLoader = ktx2Loader;
    } else if (textureSrc?.includes("basis")) {
      correctLoader = basisLoader;
    } else {
      correctLoader = textureLoader;
    }

    return correctLoader;
  }

  function prepareTexture(assetURI, texture) {
    if (assetURI?.includes("normal") || assetURI?.includes("metal") || assetURI?.includes("roughness")) {
      // handle non-color data textures
      texture.encoding = THREE.LinearEncoding;

      // if (assetURI?.includes("normalMap")) {
      // texture.minFilter = THREE.NearestFilter;
      // texture.magFilter = THREE.NearestFilter;
      // }
    } else {
      texture.encoding = THREE.sRGBEncoding;
    }

    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.flipY = false;

    return texture;
  }

  // _________________________________________________
  // end of asset loading code
  // _________________________________________________

  if (!loadersReady) return null;
  else {
    return (
      <AssetSystemContext.Provider value={getAsset}>
        <GLTFLoaderContext.Provider value={gltfLoader}>{children}</GLTFLoaderContext.Provider>
      </AssetSystemContext.Provider>
    );
  }
}
