/** Returns key/value as componentName: index
 * Used throughout the app to prevent bugs if the componentsState.array were to rearrange
 */

import { useMemo } from "react";

export const useComponentsIndex = (productsState) => {
  const componentsIndexObj = useMemo(() => {
    return {
      materialsIndex: productsState.activeObj.category.findIndex((component) => component._id === "materials"),
      patchesIndex: productsState.activeObj.category.findIndex((component) => component._id === "patches"),
      baseIndex: productsState.activeObj.category.findIndex((component) => component._id.includes("base")),
    };
  }, [productsState.activeObj.category]);

  return componentsIndexObj;
};
