import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import toastr from "toastr";
import EmbedController from "../../../../embed/EmbedController";
import AlertSlackOfError from "../../../../monitoring/AlertSlackOfError";
import { delay } from "../../../modules/delay";
import { convertToUSD, getFinalPrice } from "../../../modules/getFinalPrice";
import { getShoppingCartData } from "../../../modules/getShoppingCartData";
import { uploadImageToDigitalOcean } from "../../../modules/uploadImageToDigitalOcean";
import { canvas_base64, session_id, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { AdditionalSection, FinalNotesAtom, QuantityAtom } from "./AdditionalSection.jsx";
import { TermsConditionsSection } from "./TermsConditionSection.jsx";
import "./styles.scss";

export const ShoppingCart = ({ cartTermsObj, productsState, componentsState, itemsState }) => {
  const setItemsActiveIds = useSetAtom(update_items_activeIds);
  const quantity = useAtomValue(QuantityAtom);
  const finalNotes = useAtomValue(FinalNotesAtom);
  const [isCartSubmissionPending, setIsCartSubmissionPending] = useState(false);
  const [activeModalSection, setActiveModalSection] = useState("additionals");
  const [modalHeader, setModalHeader] = useState("TERMS AND CONDITIONS");

  const custom_image_base64 = useAtomValue(canvas_base64);
  const sessionId = useAtomValue(session_id);
  const finalPrice = getFinalPrice(productsState, componentsState, itemsState, true);

  const handleCloseModal = useCallback(() => cartTermsObj.setState(false), [cartTermsObj]);

  // setup callback for response when parent cart is updated
  useEffect(() => {
    if (EmbedController.isEmbedded) {
      EmbedController.setCartResponseCallback((errorMsg, redirectUrl) => {
        if (errorMsg || !redirectUrl) {
          console.log(`ERROR adding to shopping cart`);
          setIsCartSubmissionPending(false);
          setModalHeader("");
          setTimeout(setModalHeader("ERROR: please try again"), 500);
          alert("Error submitting to cart. Please try again.");
          AlertSlackOfError("ShoppingCart.jsx cart response", `error: ${errorMsg}`, sessionId);
        } else if (redirectUrl) {
          handleCloseModal();
          setIsCartSubmissionPending(false);
          EmbedController.sendParentUrlChange(redirectUrl);
        }
      });
    }
  }, [handleCloseModal, sessionId]);

  // if shopper has clicked "add to cart" and modal is showing for them to checkout
  useEffect(() => {
    if (cartTermsObj.state) {
      console.log("**************** initiate screenshot of product ****************");
      // signal to Scene.js to take a screenshot of caliper covers for the cart submission
      document.dispatchEvent(new CustomEvent("ScreenshotCanvasForCartImage"));
    }
  }, [cartTermsObj.state]);

  useEffect(() => {
    if (isCartSubmissionPending) setModalHeader("Enjoy Your Purchase!");
  }, [isCartSubmissionPending]);

  const generateProductBody = (productObj, cartSubmissionObj) => {
    return {
      product: {
        title: generateProductTitle(productObj),
        body_html: generateProductHtml(cartSubmissionObj.items[0].properties),
        vendor: "Thrill Seekers",
        product_type: "tastetest3d-order",
        template_suffix: "3d-customized",
        tags: ["tastetest3d-order", "tastetest3d-seat"].concat(generateProductTags(productObj)),
        variants: [
          {
            price: convertToUSD(finalPrice),
            weight: productObj.weight,
            inventory_management: null,
          },
        ],
      },
    };
  };

  const createShopifyProduct = async (productObj, cartSubmissionObj) => {
    try {
      const productBody = generateProductBody(productObj, cartSubmissionObj);
      const url = `https://${window.location.hostname}/.netlify/functions/create-shopify-product`;
      const response = await EmbedController.sendShopifyProduct(url, productBody);
      return response;
    } catch (err) {
      console.log("ERROR ShoppingCart.jsx createShopifyProduct ", err.message);
      AlertSlackOfError("ShoppingCart.jsx createShopifyProduct", `error: ${err.message}`, sessionId);
      throw err;
    }
  };

  const createProductImage = async (shopifyProductId, imageSrc) => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
    const year = currentDate.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;

    if (!EmbedController.isEmbedded) return;
    try {
      const url = `https://${window.location.hostname}/.netlify/functions/upload-shopify-image`;
      await EmbedController.sendUploadProductImage(url, {
        product_id: shopifyProductId,
        image: {
          attachment: imageSrc,
          filename: `${formattedDate}-${shopifyProductId}.png`,
        },
      });
      return true;
    } catch (err) {
      console.error("ERROR ShoppingCart.jsx createProductImage ", err.message);
      AlertSlackOfError(
        "ShoppingCart.jsx createProductImage",
        `error: ${err.message}, productId: ${shopifyProductId}, imageSrc.length: ${imageSrc?.length}, imageSrc.slice(0, 100): ${imageSrc?.slice(0, 100)}`,
        sessionId
      );
      throw err;
    }
  };

  const saveImageViaEmail = async (attachmentBase64) => {
    try {
      const url = `https://${window.location.hostname}/.netlify/functions/send-email`;
      const res = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          project: "Thrill Seekers",
          message: "Saving Uploaded Logo",
          attachmentBase64,
        }),
      });
      const data = await res.json();
      return data;
    } catch (err) {
      console.log("ERROR ShoppingCart.jsx saveImageViaEmail ", err.message);
      AlertSlackOfError("ShoppingCart.jsx saveImageViaEmail", `error: ${err.message}`, sessionId);
      return false;
    }
  };

  function generateProductTitle(productObj) {
    return `Custom Seat Cover: ${productObj.modelName}`;
  }

  function generateProductTags(productObj) {
    let dynamicTags = [];

    // seat type
    switch (productObj.tt_product_id) {
      case "full-dirtbike-seat":
        dynamicTags.push("tastetest3d-full-dirtbike-seat");
        break;
      case "small-dirtbike-seat":
        dynamicTags.push("tastetest3d-small-dirtbike-seat");
        break;
      case "atv-seat":
        dynamicTags.push("tastetest3d-atv-seat");
        break;
      default:
        break;
    }
    // seat brand
    dynamicTags.push(`tastetest3d-${productObj.brand.replace(" ", "").trim().toLowerCase()}-seat`);
    // seat model
    dynamicTags.push(`tastetest3d-seat: ${productObj.modelName.trim()}`);

    return dynamicTags;
  }

  function generateProductHtml(tt_product_properties) {
    let htmlString = ``;
    for (const [key, value] of Object.entries(tt_product_properties)) {
      htmlString += `<p><b>${key}:</b> ${value}</p>`;
    }
    return htmlString;
  }

  const submitToShoppingCart = async () => {
    setIsCartSubmissionPending(true);

    const [cartSubmissionObj] = getShoppingCartData(productsState, componentsState, itemsState, quantity, finalNotes);
    if (EmbedController.isEmbedded) {
      // If a shopper uploaded a custom logo on the prouct we need to upload the image to DO and update the cartSubmissionObj with the custom image upload url
      if (cartSubmissionObj.items[0].properties["Custom Image URL"] && cartSubmissionObj.tempCustomUploadImageInputs) {
        let DOUploadImageUrl = await uploadImageToDigitalOcean(cartSubmissionObj.items[0].properties["Custom Image URL"], "customImageUpload");
        if (DOUploadImageUrl.slice(-11) === "default.jpg") {
          // upload failed, so retry
          await delay(2000);
          DOUploadImageUrl = await uploadImageToDigitalOcean(cartSubmissionObj.items[0].properties["Custom Image URL"], "customImageUpload");
          if (DOUploadImageUrl.slice(-11) === "default.jpg") {
            // upload failed again, so send image to ourselves in slack
            await saveImageViaEmail(cartSubmissionObj.items[0].properties["Custom Image URL"]);
          }
        }
        // update the custom image upload url in the cartSubmissionObj
        cartSubmissionObj.items[0].properties["Custom Image URL"] = DOUploadImageUrl;
        cartSubmissionObj.tempCustomUploadImageInputs.inputs.uploaded_logo_src = DOUploadImageUrl;
        setItemsActiveIds({
          ...itemsState.activeIds,
          [cartSubmissionObj.customPatchFront]: cartSubmissionObj.tempCustomUploadImageInputs,
        });
        delete cartSubmissionObj.customPatchFront;
        delete cartSubmissionObj.tempCustomUploadImageInputs;

        await delay(1000);
      }

      // set the _customUrl (must be done after a custom image has been set)
      cartSubmissionObj.items[0].properties._customUrl = EmbedController?.parentUrl || window.location.href;

      try {
        // generate new shopify product to be added to cart
        const { product: newShopifyProduct } = await createShopifyProduct(productsState?.activeObj.shopify, cartSubmissionObj);
        // upload the productImage to shopify
        const imageRes = await createProductImage(newShopifyProduct.id, custom_image_base64);
        if (!imageRes) {
          console.log("image upload failed - trying again");
          await createProductImage(newShopifyProduct.id, custom_image_base64);
        }
        // attach the newly created variant.id to the cartSubmissionObj
        cartSubmissionObj.items[0].id = newShopifyProduct.variants[0].id;

        // make the main custom product show up first in the cart
        // cartSubmissionObj.items.reverse();

        console.log("Final cartSubmissionObj PROPERTIES: ", JSON.stringify(cartSubmissionObj.items[0].properties));
        EmbedController.sendAddToCart("/cart/add.js", cartSubmissionObj);
      } catch (error) {
        setIsCartSubmissionPending(false);
        setModalHeader("");
        setTimeout(setModalHeader("ERROR: please try again"), 500);
        alert("Error submitting to cart. Please try again.");
        AlertSlackOfError("ShoppingCart.jsx submitToShoppingCart error", `error: ${error}`, sessionId);
      }
    }
  };

  const cartModal = () => (
    <Modal show={cartTermsObj.state} size="md" style={{ zIndex: 1202 }} onHide={handleCloseModal}>
      {activeModalSection === "additionals" ? (
        <AdditionalSection
          activeModalSectionStateObj={{ state: activeModalSection, setState: setActiveModalSection }}
          handleCloseModal={handleCloseModal}
          componentsState={componentsState}
          itemsState={itemsState}
          quantity={quantity}
          productsState={productsState}
        />
      ) : (
        <TermsConditionsSection
          productsState={productsState}
          submitToShoppingCart={submitToShoppingCart}
          cardSubmissonPendingStateObj={{ state: isCartSubmissionPending, setState: setIsCartSubmissionPending }}
          activeModalSectionStateObj={{ state: activeModalSection, setState: setActiveModalSection }}
          modelHeaderStateObj={{ state: modalHeader, setState: setModalHeader }}
        />
      )}
    </Modal>
  );

  if (itemsState.activeObjs["custom-patch-front"]._id === "customText-front-patch") {
    if (itemsState.activeObjs["custom-patch-front"].inputs.text_input === "Add Text") {
      cartTermsObj.setState(false);
      toastr.error("Please update front patch text or remove patch.");
      return null;
    }
  }
  if (itemsState.activeObjs["custom-patches-side"]._id === "customText-side-patches") {
    if (itemsState.activeObjs["custom-patches-side"].inputs.text_input === "Add Text") {
      cartTermsObj.setState(false);
      toastr.error("Please update side patch text or remove patch.");
      return null;
    }
  }

  if (itemsState.activeObjs["custom-patch-front"]._id === "customImage-front-patch") {
    const logoSrc = itemsState.activeObjs["custom-patch-front"].uploaded_logo_src;

    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    const isValidUrl = urlPattern.test(logoSrc);

    if (!itemsState.activeObjs["custom-patch-front"].uploaded_logo_base64 && !isValidUrl) {
      cartTermsObj.setState(false);
      toastr.error("Please choose a front patch logo or remove patch.");
      return null;
    }
  }

  return <div className="ShoppingCart">{cartModal()}</div>;
};
