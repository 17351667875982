import { useMemo, useEffect } from "react";
import { Line } from "../Line/Line";
import { Boop } from "../Animations/Boop";
import { useSpring, animated } from "react-spring";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { items_state, update_items_activeIds } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import "./styles.scss";
import { pushGTMAnalyticsEvent } from "../../../modules/pushGTMAnalyticsEvent";

export const FontSection = ({ componentItems, component, ...props }) => {
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);
  const [animationProps, animate] = useSpring(() => ({
    from: { opacity: 0, scale: 0 },
  }));
  const customTextItem = useMemo(() => itemsState.array.find((item) => item._id === props.itemId), []);

  useEffect(() => {
    animate.start({
      from: { opacity: 0, scale: 0 },
      to: { opacity: 1, scale: 1 },
    });
  }, []);

  const handleClick = (index) => {
    // abort if font selected is the same as the current active font
    if (customTextItem.inputs.active_font_index === index) return;

    const copy = { ...itemsState.activeIds };

    let newActiveInput = {};

    newActiveInput._id = customTextItem._id;
    newActiveInput.inputs = customTextItem.inputs;

    newActiveInput.inputs.active_font_index = index;

    copy[component._id] = newActiveInput;
    setItemsActiveIds(copy);

    pushGTMAnalyticsEvent({
      event: "Font Item Click - Thrill",
      page: component.category,
      itemSection: "Fonts",
      item: itemsState.activeObjs[component._id].font_array[index].displayName,
    });
  };

  const fontItems = customTextItem.font_array.map((font, index) => {
    const isFontActive = index === itemsState.activeObjs[component._id].active_font_index;

    return (
      <Col key={font.displayName} xs={4}>
        <Boop boopType="scale" scale={1.05} timing={200}>
          <div className={`fontContainer px-2 py-1 ${isFontActive ? "active" : "disabled"}`} onClick={() => handleClick(index)}>
            <img src={font.preview_img_src} alt="Font" />
          </div>
        </Boop>
      </Col>
    );
  });

  return (
    <animated.div style={animationProps}>
      <div className="FontSection my-2">
        <div className="d-flex flex-column mb-2 text-center">
          <h5 className="sectionHeader mb-1">Choose Font:</h5>
          <Line color="bldeack" height="1px" width="30px" boxShadow="none" />
        </div>
        <Row className="fontItems text-center mt-3">{fontItems}</Row>
      </div>
    </animated.div>
  );
};
